// Tabs
.tabs {
  ul {
    border-bottom: 1px solid $color-grey-one;
    position: relative;
    top: 1px;

    li {
      display: inline-block;
      position: relative;
      top: 1px;
      padding: 1rem;
      list-style-type: none;
      border-top: 1px solid $color-grey-one;
      border-bottom: 1px solid $color-grey-one;
      border-left: 1px solid $color-grey-one;
      cursor: pointer;

      &:hover {
        background-color: $color-grey-xtralight;
      }

      &:last-child {
        border-right: 1px solid $color-grey-one;
      }

      &.active {
        border-top: 2px solid $color-primary;
        border-bottom: 1px solid $color-white;
        color: $color-primary;
      }
    }
  }

  .tab-content {
    display: none;
    padding: 1rem;
    border: 1px solid $color-grey-one;

    &.active {
      display: block;
      border: 1px solid $color-grey-one;
    }
  }
}

.tabs {
  position: relative;
  display: inline-flex;
  // margin-top: 2rem;

  .tab {
    margin-right: 1.5rem;
    color: #0080d3;
    border-bottom: none;
    font-weight: bold;
    padding-bottom: 4px;
    cursor: pointer;

    &.active {
      color: $color-grey;
      border-bottom: 2px solid $color-secondary;
      pointer-events: none;
    }

    &:hover:not(.active) {
      color: #0080d3;
      border-bottom: 2px solid $color-grey-lines;
    }
  }
}
